<template>
  <div>
    <ui-grid
      class="chart-category align-items-center"
      v-for="category in chartCatgories"
      :key="category.endpoint"
    >
      <ui-grid-cell columns="1">
        <div class="chart-domain column is-1">
          <div class="mb-3 font-bold">{{ category.endpoint }}</div>

          <div class="block">
            <ui-form-field
              v-for="location in category.locationList"
              :key="location"
            >
              <ui-checkbox
                v-model="category.locationFilter"
                :input-id="location"
                :value="location"
              ></ui-checkbox>
              <label
                :for="location"
                :style="
                  'font-weight: bold; color:' + colors[location.toLowerCase()]
                "
                >{{ location }}</label
              >
            </ui-form-field>
          </div>
        </div>
      </ui-grid-cell>
      <ui-grid-cell columns="11">
        <div class="column is-11">
          <ui-grid class="chart-content-wrapper">
            <ui-grid-cell
              class="chart-content"
              v-for="metric in category.metrics"
              :key="metric.text"
              columns="6"
            >
              <Echart
                :ref="category.endpoint + metric.text"
                :metric="metric.text"
                :endpoint="category.endpoint"
                :locationFilter="category.locationFilter"
                :history_range="history_range"
                :custom_range="custom_range"
                :dashboard="dashboard"
              />
            </ui-grid-cell>
          </ui-grid>
        </div>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script>
import Echart from "@/components/charts/Echart.vue";
import moment from "moment";
import config from "../../config";

export default {
  name: "Home",
  components: {
    Echart,
  },
  props: ["newMetrics", "history_range", "dashboard", "custom_range"],
  watch: {
    newMetrics: function (newVal, oldVal) {
      this.handleNewMetrics(newVal);
    },
  },
  data() {
    return {
      colors: config.colors,
      chartCatgories: [
        {
          locationList: ["UK", "Germany", "A1", "Vivacom"],
          locationFilter: ["UK", "Germany", "A1", "Vivacom"],
          endpoint: "dskdirect.bg",
          metrics: [
            { oneData: null, text: "tcp.connect" },
            { oneData: null, text: "dns.query" },
            { oneData: null, text: "http.ttfb" },
            { oneData: null, text: "https.ttfb" },
          ],
        },
        {
          locationList: ["UK", "Germany", "A1", "Vivacom"],
          locationFilter: ["UK", "Germany", "A1", "Vivacom"],
          endpoint: "www.dskdirect.bg",
          metrics: [
            { oneData: null, text: "tcp.connect" },
            { oneData: null, text: "dns.query" },
            { oneData: null, text: "http.ttfb" },
            { oneData: null, text: "https.ttfb" },
          ],
        },
        {
          locationList: ["UK", "Germany", "A1", "Vivacom"],
          locationFilter: ["UK", "Germany", "A1", "Vivacom"],
          endpoint: "dskbank.bg",
          metrics: [
            { oneData: null, text: "tcp.connect" },
            { oneData: null, text: "dns.query" },
            { oneData: null, text: "http.ttfb" },
            { oneData: null, text: "https.ttfb" },
          ],
        },
        {
          locationList: ["UK", "Germany", "A1", "Vivacom"],
          locationFilter: ["UK", "Germany", "A1", "Vivacom"],
          endpoint: "www.dskbank.bg",
          metrics: [
            { oneData: null, text: "tcp.connect" },
            { oneData: null, text: "dns.query" },
            { oneData: null, text: "http.ttfb" },
            { oneData: null, text: "https.ttfb" },
          ],
        },
        {
          locationList: ["UK", "Germany", "A1", "Vivacom"],
          locationFilter: ["UK", "Germany", "A1", "Vivacom"],
          endpoint: "mob.dskbank.bg",
          metrics: [
            { oneData: null, text: "tcp.connect" },
            { oneData: null, text: "dns.query" },
            { oneData: null, text: "http.ttfb" },
            { oneData: null, text: "https.ttfb" },
          ],
        },
      ],
      iii: 0,
    };
  },
  computed: {
    account: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
  },
  mounted() {},
  methods: {
    handleNewMetrics(metricsData) {
      const newMetrics = metricsData.filter(m => m.et == 'uri' && this.chartCatgories[0].locationList.indexOf(m.d) > -1)
      // flag variable for demo error cases
      this.iii++;

      // loop each categories
      this.chartCatgories.map((cc) => {
        // loop each metrics definitions
        cc.metrics.map((mtr) => {
          // get metrics IDs which are related to endpoint and metrics definition
          const metricsById = newMetrics.filter((nm) => {
            // check if the metric has the related ID, the related Endpoint and selected location.
            return (
              nm.m == mtr.text && nm.e == cc.endpoint
              // && cc.locationFilter.indexOf(nm.d) > -1
            );
          });

          // initialize data for a graph wih Error data(v:0) by default
          const graphData = {
            Error: {
              ok: false,
              location: "error",
              v: 0,
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Errors: {
              ok: false,
              location: "errorinfo",
              v: {},
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Anomaly: {
              anomaly: true,
              location: "anomaly",
              v: 0,
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Anomalies: {
              anomaly: true,
              location: "anomalyinfo",
              v: {},
              ts: moment(new Date()).format("HH:mm:ss"),
            },
          };
          const graphDataMulti = {
            Error: {
              ok: false,
              location: "error",
              v: 0,
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Errors: {
              ok: false,
              location: "errorinfo",
              v: {},
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Anomaly: {
              anomaly: true,
              location: "anomaly",
              v: 0,
              ts: moment(new Date()).format("HH:mm:ss"),
            },
            Anomalies: {
              anomaly: true,
              location: "anomalyinfo",
              v: {},
              ts: moment(new Date()).format("HH:mm:ss"),
            },
          }

          // loop metricIDs
          metricsById.map((mtrById, idx) => {

            // demo error cases
            // var error_cases = [
            //  2, 3, 5, 44, 54, 64, 74, 84, 94, 100, 112, 123, 140,
            // ];
            // if (error_cases.indexOf(this.iii) > -1 && mtrById.d == "A1") {
            //   mtrById.ok = false;
            // }

            // prepare one graph data
            const data = {
              anomaly: mtrById.anomaly,
              ok: mtrById.ok,
              v: mtrById.v,
              ts: moment(mtrById.ts).format("HH:mm:ss"),
              location: mtrById.d,
            };

            // handle if data.ok == false
            if (data.ok == false) {
              // to show red bar chart
              graphDataMulti["Error"].v = graphDataMulti["Error"].v < mtrById.v ? mtrById.v : graphDataMulti["Error"].v;
              graphDataMulti["Error"].ok = false

              // to show error detail in tooltip
              graphDataMulti["Errors"].v[mtrById.d] = mtrById.v;
            }
            graphDataMulti["Error"].ts = moment(mtrById.ts).format("HH:mm:ss");
            graphDataMulti["Errors"].ts = moment(mtrById.ts).format("HH:mm:ss");

            // handle if data.anomaly == true
            if (data.anomaly == true) {
              // to show red bar chart
              graphDataMulti["Anomaly"].v = graphDataMulti["Anomaly"].v < mtrById.v ? mtrById.v : graphDataMulti["Anomaly"].v;
              graphDataMulti["Anomaly"].anomaly = true

              // to show Anomaly detail in tooltip
              graphDataMulti["Anomalies"].v[mtrById.d] = mtrById.v;

            }
            graphDataMulti["Anomaly"].ts = moment(mtrById.ts).format("HH:mm:ss");
            graphDataMulti["Anomalies"].ts = moment(mtrById.ts).format("HH:mm:ss");

            // assign the data to the matched graph
            if(graphDataMulti[mtrById.d] == null || graphDataMulti[mtrById.d] == undefined) {
              graphDataMulti[mtrById.d] = []
            }
            graphDataMulti[mtrById.d].push(data);

            // if(graphDataMulti[mtrById.d].length > 1){
            //   console.log("graphDataMulti[mtrById.d]", graphDataMulti[mtrById.d])
            // }
          });

          // format error info values
          let ErrorsV = [];
          for (const key in graphDataMulti["Errors"].v) {
            if (Object.hasOwnProperty.call(graphDataMulti["Errors"].v, key)) {
              const errorItem = graphDataMulti["Errors"].v[key];
              ErrorsV.push(`${key}(${errorItem})`)
            }
          }
          graphDataMulti["Errors"].v = ErrorsV.join(", ")

          // format Anomaly info values
          let AnomaliesV = [];
          for (const key in graphDataMulti["Anomalies"].v) {
            if (Object.hasOwnProperty.call(graphDataMulti["Anomalies"].v, key)) {
              const errorItem = graphDataMulti["Anomalies"].v[key];
              AnomaliesV.push(`${key}(${errorItem})`)
            }
          }
          graphDataMulti["Anomalies"].v = AnomaliesV.join(", ")
          
          
          if (graphDataMulti["Error"].ok == false && graphDataMulti["Anomaly"].anomaly == true) {
            graphDataMulti["Error"].v = 0
          }

          // render data to the related graph on chart
          if (this.$refs[cc.endpoint + mtr.text]) {
            this.$refs[cc.endpoint + mtr.text].setChartData(graphDataMulti);
          }
          // mtr.oneData = graphData;
        });
      });
    },
  },
};
</script>

<style>
.mdc-layout-grid__inner {
  grid-gap: 0px !important;
}
.align-items-center .mdc-layout-grid__inner {
  align-items: center !important;
}
.d-flex {
  display: flex;
}
.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.chart-domain {
  font-size: 14px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.charts-wrapper {
  padding: 20px 10px;
}
.chart-category {
  margin: 0;
  padding: 0;
}
.font-bold {
  font-weight: bold;
}
</style>