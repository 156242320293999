<template>
  <div>
    <SigmaNetChart 
      v-model:newMetrics="newMetrics"
      :history_range="history_range"
      :dashboard="dashboard"
      :custom_range="custom_range" 
    />
  </div>
</template>

<script>
import SigmaNetChart from "@/components/charts/SigmaNetChart.vue";

export default {
  components: {
    SigmaNetChart,
  },
  props: ["newMetrics", "history_range", "dashboard", "custom_range"],
  watch: {
    
  },
  methods: {
  }
};
</script>

<style>
</style>