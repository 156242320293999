<template>
  <v-chart
    class="chart"
    ref="chartRef"
    :option="option"
    :loading="loadingChart"
    :autoresize="true"
  />
</template>

<script>
import moment from "moment";
import { time, use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import config from "../../config";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  GridComponent,
} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  GridComponent,
]);

const chartDataLen = 1200;

export default {
  name: "Echart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  beforeUnmount: function () {
    this.$refs.chartRef.dispose();
  },
  deactivated: function () {
    this.$refs.chartRef.dispose();
  },
  props: [
    "endpoint",
    "metric",
    "custom_range",
    "counterFilter",
    "history_range",
    "dashboard",
  ],
  watch: {
    history_range: function (newVal, oldVal) {
      console.log("pcw history_range,", newVal);
      if (newVal != "custom") {
        this.loadChartHistory();
      }
    },
    dashboard: function (newVal, oldVal) {
      // this.loadChartHistory();
    },
    counterFilter: function (newVal, oldVal) {
      this.toggleGraph(newVal);
    },
    custom_range: function (newVal, oldVal) {
      console.log("custom_range", newVal, oldVal);
      this.loadChartHistory();
    },
  },
  mounted() {
    // this.setChartTitle();
    this.loadChartHistory();
    // this.$refs.chartRef.loading = true;
  },
  data() {
    return {
      loadingChart: false,
      option: {
        // color: [
        //   "#dc3545", // red
        //   "#28a745", // green
        //   "#5470c6",
        //   "#ee6666",
        //   "#73c0de",
        //   "#3ba272",
        //   "#fc8452",
        //   "#9a60b4",
        //   "#ea7ccc",
        // ],
        title: {
          text: undefined,
          textAlign: "left",
          subtext: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        grid: {
          left: 60,
          right: 10,
          top: 50,
          bottom: 50,
        },
        legend: {
          show: true,
          data: [
            "processor.percentidletime",
            "memory.committedbytes",
            "memory.availablebytes",
            "memory.availablembytes",
          ],
        },
        toolbox: {
          show: false,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: {
          show: true,
          start: 1180,
          end: 1200,
          textStyle: {
            color: "green",
            fontWeight: "bolder",
            fontSize: 15,
            textShadowColor: "rgba(255, 255, 255, 1)",
            textShadowOffsetX: 2,
            textShadowBlur: 6,
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            // axisTick: {
            //   alignWithLabel: true,
            //   length: 8,
            // },
            axisLabel: {
              // interval: 0,
              // align: "center",
              margin: 8,
              rotate: 30,
            },
            splitLine: {
              show: false,
            },
            data: (function () {
              var now = new Date();
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.unshift(moment(now).format("HH:mm:ss"));
                now = new Date(now - 3000);
              }
              return res;
            })(),
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "ms",
            min: 0,
            boundaryGap: [0.2, 0.2],
            offset: -5,
            nameTextStyle: { align: "right" },
            axisLabel: { fontSize: 8 },
          },
        ],
        series: [
          {
            name: "Anomalies",
            connectNulls: true,
            type: "bar",
            animation: false,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            lineStyle: {
              color: config.colors.anomaly,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.anomaly,
            },
            barWidth: 10,
          },
          {
            name: "Anomaly",
            connectNulls: true,
            type: "bar",
            animation: false,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            lineStyle: {
              color: config.colors.anomaly,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.anomaly,
            },
            barWidth: 10,
            tooltip: {
              show: false
            }
          },
          {
            name: "processor.percentidletime",
            connectNulls: true,
            type: "line",
            animation: false,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            visible: false,
            lineStyle: {
              color: config.colors.uk,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.uk,
            },
          },
          {
            name: "memory.committedbytes",
            connectNulls: true,
            type: "line",
            animation: false,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            visible: false,
            lineStyle: {
              color: config.colors.germany,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.germany,
            },
          },
          {
            name: "memory.availablebytes",
            connectNulls: true,
            type: "line",
            animation: false,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            visible: false,
            lineStyle: {
              color: config.colors.a1,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.a1,
            },
          },
          {
            name: "memory.availablembytes",
            connectNulls: true,
            type: "line",
            animation: false,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = [];
              var len = chartDataLen;
              while (len--) {
                res.push({
                  value: "-",
                  symbol: "none",
                });
              }
              return res;
            })(),
            visible: false,
            lineStyle: {
              color: config.colors.vivacom,
              opacity: 0.7,
            },
            itemStyle: {
              color: config.colors.vivacom,
            },
          },
        ],
      },
    };
  },
  computed: {
    account: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
    dashboardInfos: {
      get() {
        return this.$store.getters["getDashboardInfos"];
      },
      set(val) {
        console.log("val", val);
      },
    },
  },
  methods: {
    setChartTitle() {
      console.log("setChartTitle", this.endpoint);
      let option = JSON.parse(JSON.stringify(this.option));
      option.title.text = this.endpoint;
      this.option = option;
    },

    setChartData(data) {
      if (data == null) return;
      // const option = this.option;
      // get current option from chart
      let option = this.$refs.chartRef.getOption();

      // clear all of chart. will be rebuilded when setOption
      this.$refs.chartRef.clear();

      // loop series of chart
      option.series.map((serie, idx) => {
        // update serie data: remove one from begining and add one to the end
        if (data[serie.name] != undefined) {
          let data0 = serie.data;
          if (data0.length >= chartDataLen) {
            data0.shift();
          }
          data0.push({
            value: data[serie.name].v,
            symbol: "circle",
          });
        } else {
          let data0 = serie.data;
          if (data0.length >= chartDataLen) {
            data0.shift();
          }
          data0.push({
            value: "-",
            symbol: "none",
          });
        }
      });

      // update xAxis
      if (option.xAxis[0].data.at(-1) < data["Error"].ts) {
        // update xAxis only if the comming timestamp is bigger than the current last timestamp
        option.xAxis[0].data.shift();
        option.xAxis[0].data.push(data["Error"].ts);
      }

      // set the updated option to chart
      this.$refs.chartRef.setOption(option);
      // this.option = option;
    },

    toggleGraph(counterFilter) {
      const option = this.$refs.chartRef.getOption();

      option.series.map((serie, idx) => {
        if (counterFilter.indexOf(serie.name) > -1) {
          this.$refs.chartRef.dispatchAction({
            type: "legendSelect",
            name: serie.name,
          });
        } else if (serie.name != "Error") {
          this.$refs.chartRef.dispatchAction({
            type: "legendUnSelect",
            name: serie.name,
          });
        }
      });
    },

    loadChartHistory() {
      // const historyApi = `http://localhost:7071/api/measures`;
      const historyApi = `${this.$apiUrl}api/measures`

      const params = {
        name: "pcw",
        entity: this.endpoint,
        metric: "all",
        dashboard: this.dashboard,
        userId: this.account.localAccountId,
        since: this.history_range,
        startTime: this.custom_range ? this.custom_range[0] : null,
        endTime: this.custom_range ? this.custom_range[1] : null,
      };

      this.loadingChart = true;

      this.axios
        .get(historyApi, {
          params: params,
        })
        .then((response) => {
          this.setChartHistory(JSON.parse(response.data));
        })
        .catch((err) => {
          console.log("err ", err);
          this.loadingChart = false;
          this.$toast.error(
            `${err} - ${historyApi} with params ${JSON.stringify(params)}`,
            {
              position: "bottom",
              duration: false,
            }
          );
        });
    },

    setChartHistory(data) {
      console.log("setChartHistory", data);
      if (
        data == null ||
        data.length == 0 ||
        this.$refs.chartRef == null ||
        data == "[]"
      ) {
        this.loadingChart = false;
        return;
      }
      // get current option from chart
      let option = this.$refs.chartRef.getOption();

      // clear all of chart. will be rebuilded when setOption
      this.$refs.chartRef.clear();

      // object that has each location(d) as property
      const valuesByMetric = {};
      // initialize with {}
      option.series.map((serie, idx) => {
        valuesByMetric[serie.name] = {};
      });
      valuesByMetric["Error"] = {};
      valuesByMetric["Errors"] = {};

      // loop data and get timestamps, data separately
      let xAxisData = [];
      data.map((item) => {
        // get formated timestamp using moment
        const momentTs = moment(item.ts).format("HH:mm:ss");
        // get timestamps for xaxis
        // timestamps.push(momentTs);
        xAxisData.push(item.ts);

        if (valuesByMetric[item.m]) {
          valuesByMetric[item.m][momentTs + ""] = { ...item }; // insert value to [location(d)][timestamp]

          if (item.anomaly == true) {
            if (valuesByMetric["Anomaly"][momentTs]) {
              valuesByMetric["Anomaly"][momentTs] = valuesByMetric["Anomaly"][momentTs].v < item.v ? {...item} : valuesByMetric["Anomaly"][momentTs];
            } else {
              valuesByMetric["Anomaly"][momentTs] = {...item};
            }

            if (valuesByMetric["Anomalies"][momentTs]) {
              valuesByMetric["Anomalies"][momentTs].v += `, ${item.m}(${item.v})`
            } else {
              valuesByMetric["Anomalies"][momentTs] = {...item};
              valuesByMetric["Anomalies"][momentTs].v = `${item.m}(${item.v})`
            }
          }
        }
      });

      // update xAxis
      // sort timestamps array
      xAxisData.sort();
      var tempXAxisData = option.xAxis[0].data;
      xAxisData.forEach(function (part, index, xAxisData) {
        tempXAxisData.shift();
        tempXAxisData.push(moment(part).format("HH:mm:ss"));
        // option.xAxis[0].data.shift();
        // option.xAxis[0].data.push(moment(part).format("HH:mm:ss"));
      });
      option.xAxis[0].data = tempXAxisData;

      // loop series of chart and update serie data
      option.series.map((serie, idx) => {
        const values = [];
        let data0 = serie.data;

        let noneV = "-";
        // loop timestamps and match values to each timestamp
        tempXAxisData.map((ts) => {
          // get metric data by location and timestamp
          const found = valuesByMetric[serie.name][ts];
          if (found) {
            // if exists, put the value with circle symbol
            if (data0.length >= chartDataLen) {
              data0.shift();
            }
            data0.push({
              value: found.v,
              symbol: "circle",
            });
          } else {
            // if doesn't exist, put the "-" with no symbol
            if (data0.length >= chartDataLen) {
              data0.shift();
            }
            data0.push({
              value: noneV,
              symbol: "none",
            });
          }
        });

        // set the completed values to serie
        serie.data = data0;
      });

      // set the updated option to chart
      this.$refs.chartRef.setOption(option);
      this.loadingChart = false;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
