<template>
  <div class="home">
    <div class="charts-wrapper" v-if="true">
      <!-- <ui-grid>
        <ui-grid-cell columns="2"> Number of Points </ui-grid-cell>
        <ui-grid-cell columns="10">
          <div style="width: 90%">
            <ui-slider
              v-model="numberOfPoints"
              type="discrete"
              :step="10"
              :min="5"
              :max="1200"
              @update:modelValue="changeNumberPoints"
            ></ui-slider>
          </div>
        </ui-grid-cell>
      </ui-grid> -->

      <div style="display: flex; justify-content: space-between">
        <div>
          <div style="display: flex">
            <ui-card outlined v-if="environmentName">
              <ui-card-content>
                <div style="padding: 12px">
                  <span style="color: gray; font-size: 12px">environment</span
                  ><br />{{ environmentName }}
                </div>
              </ui-card-content>
            </ui-card>
            <ui-card outlined style="margin-left: 15px" v-if="zoneName">
              <ui-card-content>
                <div style="padding: 12px">
                  <span style="color: gray; font-size: 12px">zone</span><br />{{
                    zoneName
                  }}
                </div>
              </ui-card-content>
            </ui-card>
          </div>
        </div>
        <div style="display: flex">
          <ui-select
            id="full-func-js-select"
            v-model="dashboard"
            :options="dashboard_options"
            @selected="onSelectedDashboard"
            style="margin-right: 5px"
          >
            Dashboard
          </ui-select>

          <ui-select
            id="full-func-js-select"
            v-model="history_range"
            :options="history_range_options"
            @selected="onSelectedHistoryRange"
            style="margin-right: 5px"
          >
            History Range
          </ui-select>

          <div>
            <date-range-picker
              v-if="history_range == 'custom'"
              :options="options"
              format="DD/MM/YYYY HH:mm"
              class="custom-date-range-picker"
              @change="customRangeChange"
            />
          </div>
        </div>
      </div>

      <div>
        <div v-if="dashboard == 'global' || dashboard == 'dskdirect'">
          <SyntheticWrapper
            v-model:newMetrics="newMetrics"
            :history_range="history_range"
            :dashboard="dashboard"
            :custom_range="custom_range"
          />
        </div>

        <div v-else-if="dashboard == 'pcw'">
          <PcwWrapper
            v-model:newMetrics="newMetrics"
            :history_range="history_range"
            :dashboard="dashboard"
            :custom_range="custom_range"
          />
        </div>

        <div v-else-if="dashboard == 'topology'">
          <EtwWrapper 
            v-model:newMetrics="newMetrics"
            :history_range="history_range"
            :dashboard="dashboard"
            :custom_range="custom_range" />
        </div>

        <div v-else>
          <ui-spinner
            :active="true"
            style="
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            "
          ></ui-spinner>
        </div>
      </div>
    </div>
    <div id="console" ref="console"></div>
  </div>
</template>

<script>
import Echart from "@/components/charts/Echart.vue";
import SyntheticWrapper from "@/components/synthetic/index.vue";
import PcwWrapper from "@/components/pcw/index.vue";
import EtwWrapper from "@/components/etw/index.vue";
import moment from "moment";
import config from "../config";
const signalR = require("@microsoft/signalr");

export default {
  name: "Home",
  components: {
    Echart,
    SyntheticWrapper,
    PcwWrapper,
    EtwWrapper,
  },
  data() {
    return {
      custom_range: [false, false],
      options: {
        timePicker: true,
        startDate: moment().startOf("hour").add(-3, "hour"),
        endDate: moment().startOf("hour"),
        locale: {
          format: "M/DD HH:mm",
        },
        opens: "left",
        timePicker24Hour: true,
      },
      newMetrics: [],
      colors: config.colors,
      environmentName: null,
      zoneName: null,
      numberOfPoints: 43,
      history_range: "15m",
      dashboard: "global",
      history_range_options: [
        {
          label: "15 minutes",
          value: "15m",
        },
        {
          label: "30 minutes",
          value: "30m",
        },
        {
          label: "1 hour",
          value: "1h",
        },
        {
          label: "2 hours",
          value: "2h",
        },
        {
          label: "Custom range",
          value: "custom",
        },
      ],
      dashboard_options: [],
    };
  },
  computed: {
    account: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
  },
  mounted() {
    this.loadDashboardConf();
    this.connectServer();
    this.dashboard = this.$route.query.dashboard;
    this.$router.push(`/charts?dashboard=${this.dashboard}`);
  },
  methods: {
    customRangeChange(range) {
      console.log("customRangeChange: ", range);
      if (range) {
        this.custom_range = range;
      }
    },
    async loadDashboardConf() {
      // const dashboardConfApi = `http://localhost:7071/api/dashboard`;
      const dashboardConfApi = `${this.$apiUrl}api/dashboard`;
      const params = {
        userId: this.account.localAccountId,
      };

      this.axios
        .get(dashboardConfApi, {
          params: params,
        })
        .then((response) => {
          console.log("response ", response);
          const options = [];
          response.data.dashboards.map((item) => {
            options.push({
              value: item.name,
              label: item.title,
            });
          });

          // store original dashboards data
          this.$store.dispatch("setDashboardInfos", response.data);

          this.dashboard_options = options;
          if (this.dashboard == undefined || this.dashboard == "global") {
            this.dashboard = options[0].value;
          }

          this.environmentName = response.data.environments[0].title;
          this.zoneName = response.data.zones[0].title;
        })
        .catch((err) => {
          console.log("err ", err);
          this.$toast.error(
            `${err} - ${dashboardConfApi} with params ${JSON.stringify(
              params
            )}`,
            {
              position: "bottom",
              duration: false,
            }
          );
        });
    },
    onSelectedHistoryRange(selected) {
      this.history_range = selected.value;
    },
    onSelectedDashboard(selected) {
      console.log("onSelectedDashboard", selected);
      this.dashboard = selected.value;
      this.$router.push(`/charts?dashboard=${selected.value}`);
    },
    connectServer() {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${this.$apiUrl}api`)
        .withAutomaticReconnect([
          0,
          1000,
          1000,
          1000,
          1000,
          1000,
          5000,
          5000,
          5000,
          5000,
          5000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          30000,
          30000,
          30000,
          30000,
          30000,
          60000,
          60000,
          60000,
          60000,
          60000,
          600000,
          600000,
          600000,
          600000,
          600000,
          600000,
          null,
        ])
        .configureLogging(signalR.LogLevel.Information)
        .build();
      connection.on("newMetric", this.onNewMetrics);
      connection.onclose((res) => {
        console.log("disconnected");
        console.log(res);
      });

      console.log("connecting...");
      connection
        .start()
        .then(() => {
          console.log("connected");
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(`${err} - ${this.$apiUrl}api`, {
            position: "bottom",
            duration: false,
          });
        });
    },
    onNewMetrics(newMetrics) {
      newMetrics = JSON.parse(newMetrics);
      console.log("newMetrics", newMetrics);
      this.newMetrics = newMetrics;
    },
    changeNumberPoints(number) {
      console.log("number", number);
    },
  },
};
</script>

<style>
.custom-date-range-picker {
  height: 53px;
  cursor: pointer;
  font-size: 15px;
  background: whitesmoke;
  border: none;
  border-bottom: 1px solid gray;
  padding: 1px 0 1px 15px;
}
.custom-date-range-picker:focus {
  outline: none !important;
}
.custom-date-range-picker:focus-visible {
  outline: none !important;
}

.mdc-layout-grid__inner {
  grid-gap: 0px !important;
}
.align-items-center .mdc-layout-grid__inner {
  align-items: center !important;
}
.d-flex {
  display: flex;
}
.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.chart-domain {
  font-size: 14px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.charts-wrapper {
  padding: 20px 10px;
}
.chart-category {
  margin: 0;
  padding: 0;
}
.font-bold {
  font-weight: bold;
}
</style>