<template>
  <div>
    <ui-grid
      class="chart-category align-items-center"
      style="margin-bottom: 40px; margin-top: 50px"
      v-for="category in chartCatgories"
      :key="category.endpoint"
    >
      <ui-grid-cell columns="12">
        <div class="column is-12">
          <h4>{{ category.endpoint }}</h4>
          <EchartPCW
            :ref="category.endpoint"
            :endpoint="category.endpoint"
            :counterFilter="category.counterFilter"
            :history_range="history_range"
            :dashboard="dashboard"
            :custom_range="custom_range"
          />
        </div>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script>
import EchartPCW from "@/components/charts/EchartPCW.vue";
import moment from "moment";
import config from "../../config";

export default {
  name: "Home",
  components: {
    EchartPCW,
  },
  props: ["newMetrics", "history_range", "dashboard", "custom_range"],
  watch: {
    newMetrics: function (newVal, oldVal) {
      this.handleNewMetrics(newVal);
    },
  },
  data() {
    return {
      colors: config.colors,
      endpoints: config.pcw_hosts.map((item) => item.name),
      chartCatgories: [
        // {
        //   counterList: config.counters,
        //   counterFilter: ["processor.percentidletime", "memory.committedbytes", "memory.availablebytes", "memory.availablembytes"],
        //   endpoint: "PC2028"
        // },
        {
          counterList: config.counters,
          counterFilter: [
            "processor.percentidletime",
            "memory.committedbytes",
            "memory.availablebytes",
            "memory.availablembytes",
          ],
          endpoint: config.pcw_hosts[0].name,
        },
        {
          counterList: config.counters,
          counterFilter: [
            "processor.percentidletime",
            "memory.committedbytes",
            "memory.availablebytes",
            "memory.availablembytes",
          ],
          endpoint: config.pcw_hosts[1].name,
        },
        {
          counterList: config.counters,
          counterFilter: [
            "processor.percentidletime",
            "memory.committedbytes",
            "memory.availablebytes",
            "memory.availablembytes",
          ],
          endpoint: config.pcw_hosts[2].name,
        },
        {
          counterList: config.counters,
          counterFilter: [
            "processor.percentidletime",
            "memory.committedbytes",
            "memory.availablebytes",
            "memory.availablembytes",
          ],
          endpoint: config.pcw_hosts[3].name,
        },
      ],
    };
  },
  computed: {
    account: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
  },
  mounted() {},
  methods: {
    handleNewMetrics(newMetrics) {
      const pcwMetrics = newMetrics.filter(
        (m) => m.et == "host" && this.endpoints.indexOf(m.e) > -1
      );
      // loop each categories
      this.chartCatgories.map((cc) => {
        const metricsByEntity = pcwMetrics.filter((nm) => {
          return nm.e == cc.endpoint;
        });

        // initialize data for a graph wih Error data(v:0) by default
        const graphData = {
          Error: {
            ok: false,
            location: "error",
            v: 0,
            ts: moment(new Date()).format("HH:mm:ss"),
          },
          Anomaly: {
            anomaly: true,
            location: "anomaly",
            v: 0,
            ts: moment(new Date()).format("HH:mm:ss"),
          },
          Anomalies: {
            anomaly: true,
            location: "anomalyinfo",
            v: {},
            ts: moment(new Date()).format("HH:mm:ss"),
          },
        };

        // loop metricIDs
        metricsByEntity.map((mtrById, idx) => {
          // prepare one graph data
          const data = {
            anomaly: mtrById.anomaly,
            ok: mtrById.ok,
            v: mtrById.v,
            ts: moment(mtrById.ts).format("HH:mm:ss"),
          };

          // handle if data.ok == false
          if (data.ok == false) {
            // to show red bar chart
            graphData["Error"].v =
              graphData["Error"].v < mtrById.v
                ? mtrById.v
                : graphData["Error"].v;
          }

          // handle if data.anomaly == true
          if (data.anomaly == true) {
            // to show red bar chart
            graphData["Anomaly"].v =
              graphData["Anomaly"].v < mtrById.v
                ? mtrById.v
                : graphData["Anomaly"].v;

            // to show Anomaly detail in tooltip
            graphData["Anomalies"].v[mtrById.m] = mtrById.v;
          }
          graphData["Anomaly"].ts = moment(mtrById.ts).format("HH:mm:ss");
          graphData["Anomalies"].ts = moment(mtrById.ts).format("HH:mm:ss");

          // assign the data to the matched graph
          graphData[mtrById.m] = data;
        });

        // format Anomaly info values
        let AnomaliesV = [];
        for (const key in graphData["Anomalies"].v) {
          if (Object.hasOwnProperty.call(graphData["Anomalies"].v, key)) {
            const errorItem = graphData["Anomalies"].v[key];
            AnomaliesV.push(`${key}(${errorItem})`);
          }
        }
        graphData["Anomalies"].v = AnomaliesV.join(", ");

        // render data to the related graph on chart
        if (this.$refs[cc.endpoint]) {
          this.$refs[cc.endpoint].setChartData(graphData);
        }
      });
    },
  },
};
</script>

<style>
.mdc-layout-grid__inner {
  grid-gap: 0px !important;
}
.align-items-center .mdc-layout-grid__inner {
  align-items: center !important;
}
.d-flex {
  display: flex;
}
.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.chart-domain {
  font-size: 14px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.charts-wrapper {
  padding: 20px 10px;
}
.chart-category {
  margin: 0;
  padding: 0;
}
.font-bold {
  font-weight: bold;
}
</style>